import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/card-detail',
      name: 'card-detail',
      component: () => import('@/views/CardDetail.vue'),
      meta: {
        pageTitle: 'Card Detail',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Card Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/phone-card-detail',
      name: 'phone-card-detail',
      component: () => import('@/views/PhoneCardDetail.vue'),
      meta: {
        pageTitle: 'Phone Card Detail',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Phone Card Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tag-detail',
      name: 'tag-detail',
      component: () => import('@/views/TagDetail.vue'),
      meta: {
        pageTitle: 'Tag Detail',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Tag Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/Contacts.vue'),
      meta: {
        pageTitle: 'Contacts',
        breadcrumb: [
          {
            text: 'Contacts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
