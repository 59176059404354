import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://api.simpra.kz',
  timeout: 10000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
