/* eslint-disable global-require */

import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    cardProductDetail: {
      id: 1,
      name: 'Simpra Card',
      slug: 'simpra-card',
      price: '275.00',
      hasFreeShipping: true,
      rating: 5,
      colorOptions: ['cardBlack', 'cardWhite', 'cardMultiColor'],
      selectedPicture: 'tag',
    },
    cardConfigrator: {
      cardColorType: 'cardBlack',
    },
    phoneCardProductDetail: {
      id: 3,
      name: 'Simpra Phone Card',
      slug: 'simpra-phone-card',
      price: '275.00',
      hasFreeShipping: true,
      rating: 5,
      colorOptions: ['cardBlack', 'cardWhite', 'cardMultiColor'],
      selectedPicture: 'tag',
    },
    phoneCardConfigrator: {
      cardColorType: 'cardBlack',
    },
    tagProductDetail: {
      id: 2,
      name: 'Simpra Tag',
      slug: 'simpra-tag',
      price: '200.00',
      hasFreeShipping: true,
      rating: 5,
      colorOptions: ['tagBlack', 'tagWhite', 'tagMultiColor'],
      selectedPicture: 'tag',
    },
    tagConfigrator: {
      tagColorType: 'tagBlack',
    },
    basketList: [],
    user: {
      name: '',
      surname: '',
      identity_number: '',
      email: '',
      gsm_number: '',
      registration_address: '',
      city: '',
      country: '',
      zip_code: '',
    },
    payment_card: {
      card_holder_name: '',
      card_number: '',
      expire_year: '',
      expire_month: '',
      cvc: '',
    },
  },
  getters: {
    // Product
    getCardProductDetail: state => state.cardProductDetail,
    getCardConfigrator: state => state.cardConfigrator,
    getCardColorType: state => state.cardConfigrator.cardColorType,

    getCardFlipState: state => state.cardConfigrator.cardFlipState,
    getCardName: state => state.cardConfigrator.cardName,
    getCardQrState: state => state.cardConfigrator.cardQrState,
    getCardQrLocation: state => state.cardConfigrator.cardQrLocation,

    getPhoneCardProductDetail: state => state.phoneCardProductDetail,
    getPhoneCardConfigrator: state => state.phoneCardConfigrator,
    getPhoneCardColorType: state => state.phoneCardConfigrator.cardColorType,

    getTagProductDetail: state => state.tagProductDetail,
    getTagConfigrator: state => state.tagConfigrator,
    getTagColorType: state => state.tagConfigrator.tagColorType,

    // Basket
    getBasketList: state => state.basketList,
    getBasketListCount: state => state.basketList.length,

    // Checkout
    getUserPaymentCart: state => state.payment_card,
    getUserInfo: state => state.user,
  },
  mutations: {
    // Product Card
    restoreCardConfigrator(state) {
      state.cardConfigrator = {
        cardColorType: 'cardBlack',
        cardFlipState: false,
        cardName: '',
        cardQrState: false,
        cardQrLocation: '',
      }
    },
    setCardConfigrator(state, payload) {
      state.cardConfigrator = payload
    },
    setCardConfigratorParams(state, payload) {
      state.cardConfigrator[payload.params] = payload.value
    },
    setCardSelectedPicture(state, payload) {
      state.cardProductDetail.selectedPicture = payload
    },
    // Product PhoneCard
    restorePhoneCardConfigrator(state) {
      state.phoneCardConfigrator = {
        cardColorType: 'cardBlack',
        cardFlipState: false,
        cardName: '',
        cardQrState: false,
        cardQrLocation: '',
      }
    },
    setPhoneCardConfigrator(state, payload) {
      state.phoneCardConfigrator = payload
    },
    setPhoneCardConfigratorParams(state, payload) {
      state.phoneCardConfigrator[payload.params] = payload.value
    },
    setPhoneCardSelectedPicture(state, payload) {
      state.phoneCardProductDetail.selectedPicture = payload
    },
    // Product Tag
    restoreTagConfigrator(state) {
      state.tagConfigrator = {
        tagColorType: 'tagBlack',
      }
    },
    setTagConfigrator(state, payload) {
      state.tagConfigrator = payload
    },
    setTagConfigratorParams(state, payload) {
      state.tagConfigrator[payload.params] = payload.value
    },
    setSelectedPicture(state, payload) {
      state.tagProductDetail.selectedPicture = payload
    },

    // Basket
    setBasketItem(state, payload) {
      state.basketList.push(payload)
    },
    removeBasketItem(state, itemId) {
      state.basketList = state.basketList.filter(x => x.id !== itemId)
    },
    restoreBasketList(state) {
      state.basketList = []
    },

    // Checkout
    setUserInfoParams(state, payload) {
      state.user[payload.params] = payload.value
    },
    restoreUserInfo(state) {
      state.user = {
        name: '',
        surname: '',
        identity_number: '',
        email: '',
        gsm_number: '',
        registration_address: '',
        city: '',
        country: '',
        zip_code: '',
      }
    },
    setUserPaymentCartParams(state, payload) {
      state.payment_card[payload.params] = payload.value
    },
    restoreUserPaymentCart(state) {
      state.payment_card = {
        card_holder_name: '',
        card_number: '',
        expire_year: '',
        expire_month: '',
        cvc: '',
      }
    },
  },
  actions: {
    // Product Card
    changeCardColors({ commit }, payload) {
      const colorParams = {
        params: 'cardColorType',
        value: payload,
      }
      commit('setCardConfigratorParams', colorParams)
    },
    changeCardSelectedPicture({ commit }, payload) {
      commit('setCardSelectedPicture', payload)
    },

    // Product Card
    changePhoneCardColors({ commit }, payload) {
      const colorParams = {
        params: 'phoneCardColorType',
        value: payload,
      }
      commit('setPhoneCardConfigratorParams', colorParams)
    },
    changePhoneCardSelectedPicture({ commit }, payload) {
      commit('setPhoneCardSelectedPicture', payload)
    },

    // Product Card
    changeTagColors({ commit }, payload) {
      const colorParams = {
        params: 'tagColorType',
        value: payload,
      }
      commit('setTagConfigratorParams', colorParams)
    },
    changeSelectedPicture({ commit }, payload) {
      commit('setSelectedPicture', payload)
    },

    // Basket
    addBasketItem({ commit }, payload) {
      commit('setBasketItem', payload)
    },
    deleteBasketItem({ commit }, itemId) {
      commit('removeBasketItem', itemId)
    },
    restoreBasket({ commit }) {
      commit('restoreBasketList')
    },

    // Checkout
    updateUserInfoParams({ commit }, payload) {
      commit('setUserInfoParams', payload)
    },
    cleanUserInfo({ commit }) {
      commit('restoreUserInfo')
    },
    updateUserPaymentCartParams({ commit }, payload) {
      commit('setUserPaymentCartParams', payload)
    },
    cleanUserPaymentCart({ commit }) {
      commit('restoreUserPaymentCart')
    },
    // eslint-disable-next-line no-unused-vars
    createPaymentReq({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/order', payload)
          .then(resp => {
            // commit('restoreUserPaymentCart')
            // commit('restoreUserInfo')
            // commit('restoreBasketList')
            resolve(resp)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    checkDiscountCode(_, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/order/discount/${payload}`)
          .then(resp => {
            resolve(resp)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
  },
}
